:root {
  --pbpadding: 3em;
  --pbdoublepadding: 6em;
  --pbtriplepadding: 9em;
}

:root {
  --base-font-size: 17px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-size: 17px;
  font-family: "Mont-light", Arial;
  font-variation-settings: "wght" 400;
  background-color: white;
  position: relative;
}

p {
  font-size: var(--base-font-size);
  line-height: 1.65em;
  margin-bottom: 1.25em;
}

strong,
b {
  font-family: "Mont-bold";
  font-variation-settings: "wght" 600;
}

img {
  height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
  margin-top: 1rem;
}

p + .keylink {
  margin-top: 1em;
}

p + ul {
  margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
  color: #000;
  text-decoration: none;
}
a:not(.nav-link, .skiplink, .reset-link):hover, .link:hover {
  text-decoration: underline;
}

article.text-side a, article a {
  text-underline-offset: 0.3em;
}

.reset-link {
  text-decoration: none !important;
  color: black !important;
}
.reset-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}

:is(.backto.backto) {
  text-decoration: none;
  opacity: 0.6;
  margin-top: 2rem;
  display: inline-block;
}
:is(.backto.backto) img {
  width: 27px;
  margin-right: 5px;
}
:is(.backto.backto):hover {
  text-decoration: underline;
}

.mysuperflexteststyle {
  background-color: red;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

div.objectfit {
  height: 100%;
}
div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0;
}

.skiplink {
  position: absolute;
  left: -9999em;
}
.skiplink:focus {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 2rem;
  z-index: 1000;
  background: #000000;
  padding: 1rem 1.8rem;
  border: 4px solid white;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.withflex {
  display: flex;
}

.centred {
  text-align: center;
}

.align-right {
  text-align: right;
}

.gorel {
  position: relative;
}

.noflow {
  overflow: hidden;
}

.rounded-box {
  border-radius: 18px;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue {
  background-color: #db0132;
  color: #FFF;
}

.bg-grey {
  background-color: #ededed;
}

.bg-greylt {
  background-color: #F5F5F5;
}

.bg-sky {
  background-color: #ededed;
}

.gowhite {
  color: #FFF;
}

.goblue {
  color: #db0132;
}

.bordered-blue {
  border: 0.5px solid #db0132;
}

.with-margin-top {
  margin-top: var(--pbpadding);
}

.with-margin-bottom {
  margin-bottom: var(--pbpadding);
}

.nomargin-top {
  margin-top: 0 !important;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.noflow {
  overflow: hidden;
}

.smaller-text {
  font-size: 13px;
}
.smaller-text h2 {
  font-size: 26px;
}
.smaller-text p {
  font-size: 13px;
}

@media (max-width: 768px) {
  p + .keylink {
    margin-top: 0.25em;
  }
}
.pb-whychoose-panel {
  margin-top: 4.5em;
  padding: 100px 0 350px;
  position: relative;
}
.pb-whychoose-panel h3 + p {
  margin-top: 2.25rem;
}
.pb-whychoose-panel .midcol .leftcol {
  flex-basis: 50%;
}
.pb-whychoose-panel .copy-wrapper {
  display: flex;
}
.pb-whychoose-panel .copy-wrapper .leftcol {
  flex-basis: 50%;
  min-width: 0;
}
.pb-whychoose-panel .copy-wrapper .leftcol p {
  max-width: 520px;
}
.pb-whychoose-panel .feature-circle {
  background-image: url("../../assets/images/faded_ring.svg");
  background-size: 108%;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%;
  max-width: 805px;
  padding: 80px;
  right: -7%;
  top: -24%;
  position: absolute;
}
.pb-whychoose-panel .feature-circle img {
  display: block;
}
.pb-whychoose-panel .feature-circle .panel-feature {
  background-color: #db0132;
  color: white;
  width: 8%;
  padding-top: 8%;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  transition: all 0.25s linear;
}
.pb-whychoose-panel .feature-circle .panel-feature .background, .pb-whychoose-panel .feature-circle .panel-feature .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  position: absolute;
}
.pb-whychoose-panel .feature-circle .panel-feature .background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  opacity: 1;
}
.pb-whychoose-panel .feature-circle .panel-feature .content {
  opacity: 0;
}
.pb-whychoose-panel .feature-circle .panel-feature .content .feature-description {
  min-width: 180px;
  max-width: 100%;
  padding: 10px 10px 0;
  font-size: clamp(13px, 1vw, 16px);
  text-align: center;
  line-height: 1.2em;
  display: block;
}
.pb-whychoose-panel .feature-circle .panel-feature.active {
  background-image: none !important;
  width: 22.5%;
  padding-top: 22.5%;
}
.pb-whychoose-panel .feature-circle .panel-feature.active .background {
  opacity: 0;
}
.pb-whychoose-panel .feature-circle .panel-feature.active .content {
  opacity: 1;
}
.pb-whychoose-panel .feature-circle .panel-feature.active .content img {
  width: auto;
  height: 40px;
}
.pb-whychoose-panel .feature-circle #pfeature-1 {
  left: 2%;
  top: 31%;
}
.pb-whychoose-panel .feature-circle #pfeature-2 {
  left: -0.15%;
  top: 44%;
}
.pb-whychoose-panel .feature-circle #pfeature-2.active {
  left: -6.5%;
  top: 43.25%;
}
.pb-whychoose-panel .feature-circle #pfeature-3 {
  left: 5.75%;
  top: 69.75%;
}
.pb-whychoose-panel .feature-circle #pfeature-3.active {
  left: -4.25%;
  top: 56%;
}
.pb-whychoose-panel .feature-circle #pfeature-4 {
  left: 14%;
  top: 80%;
}
.pb-whychoose-panel .feature-circle #pfeature-5 {
  left: 25.5%;
  top: 87.25%;
}
.pb-whychoose-panel .feature-circle.circle-target-1 #pfeature-1.active {
  left: -7.75%;
  top: 30.15%;
}
.pb-whychoose-panel .feature-circle.circle-target-1 #pfeature-2 {
  left: 1.2%;
  top: 57.5%;
}
.pb-whychoose-panel .feature-circle.circle-target-4 #pfeature-4.active {
  left: 2%;
  top: 69%;
}
.pb-whychoose-panel .feature-circle.circle-target-4 #pfeature-3 {
  left: 1.2%;
  top: 57.5%;
}
.pb-whychoose-panel .feature-circle.circle-target-5 #pfeature-5.active {
  left: 12.5%;
  top: 78.5%;
}
.pb-whychoose-panel .feature-circle.circle-target-5 #pfeature-3 {
  left: 1.2%;
  top: 57.5%;
}
.pb-whychoose-panel .feature-circle.circle-target-5 #pfeature-4 {
  left: 5.75%;
  top: 69.75%;
}
.pb-whychoose-panel .feature-carousel {
  max-width: 410px;
  margin: 45px auto 0;
  position: relative;
  z-index: 10;
  display: none;
}
.pb-whychoose-panel .feature-carousel img {
  display: block;
}
.pb-whychoose-panel .feature-carousel .panel-feature {
  background-color: #db0132;
  color: white;
  width: 60px;
  margin: 6px;
  padding-top: 60px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: all 0.25s linear;
}
.pb-whychoose-panel .feature-carousel .panel-feature .background, .pb-whychoose-panel .feature-carousel .panel-feature .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  position: absolute;
}
.pb-whychoose-panel .feature-carousel .panel-feature .background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  opacity: 1;
}
.pb-whychoose-panel .feature-carousel .panel-feature .content {
  opacity: 0;
}
.pb-whychoose-panel .feature-carousel .panel-feature .content .feature-description {
  min-width: 120px;
  max-width: 100%;
  padding: 10px 10px 0;
  font-size: 11px;
  text-align: center;
  line-height: 1.2em;
  display: block;
}
.pb-whychoose-panel .feature-carousel .slick-track {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 550ms ease-in-out;
}
.pb-whychoose-panel .feature-carousel .slick-slide {
  height: 60px;
}
.pb-whychoose-panel .feature-carousel .slick-slide.slick-current {
  height: 120px;
}
.pb-whychoose-panel .feature-carousel .slick-slide.slick-current .panel-feature {
  background-image: none !important;
  width: 120px;
  padding-top: 120px;
}
.pb-whychoose-panel .feature-carousel .slick-slide.slick-current .panel-feature .background {
  opacity: 0;
}
.pb-whychoose-panel .feature-carousel .slick-slide.slick-current .panel-feature .content {
  opacity: 1;
}
.pb-whychoose-panel .feature-carousel .slick-slide.slick-current .panel-feature .content img {
  width: auto;
  height: 40px;
}
.pb-whychoose-panel .feature-carousel-nav {
  width: 100%;
  height: 14px;
  padding: 0 12px;
  transform: translateY(-75px);
  max-width: 475px;
  margin: 0 auto;
  display: none;
}
.pb-whychoose-panel .feature-carousel-nav .prev-btn-feature-carousel, .pb-whychoose-panel .feature-carousel-nav .next-btn-feature-carousel {
  background-image: url("../../assets/images/icon_carousel_arrow_red.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  height: 14px;
  display: block;
  float: left;
}
.pb-whychoose-panel .feature-carousel-nav .prev-btn-feature-carousel {
  transform: rotate(180deg);
}
.pb-whychoose-panel .feature-carousel-nav .next-btn-feature-carousel {
  float: right;
}
@media (max-width: 1200px) {
  .pb-whychoose-panel .feature-circle .panel-feature .content .feature-description {
    min-width: 100%;
    font-size: 1vw;
  }
}
@media (max-width: 1000px) {
  .pb-whychoose-panel {
    margin-top: 0;
    padding-top: var(--pbpadding);
    padding-bottom: 10em;
  }
  .pb-whychoose-panel .copy-wrapper {
    display: block;
  }
  .pb-whychoose-panel .copy-wrapper .leftcol {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .pb-whychoose-panel .copy-wrapper .leftcol p {
    margin-left: auto;
    margin-right: auto;
  }
  .pb-whychoose-panel .copy-wrapper .leftcol .key-heading {
    margin: 0 auto;
  }
  .pb-whychoose-panel .feature-circle {
    width: 85%;
    margin: 2em auto 0;
    top: inherit;
    right: -1.5em;
    position: relative;
  }
  .pb-whychoose-panel .feature-circle .panel-feature .content .feature-description {
    font-size: 1.7vw;
  }
}
@media (max-width: 767px) {
  .pb-whychoose-panel {
    margin-bottom: 10.25em;
    padding-bottom: 125px;
  }
  .pb-whychoose-panel .feature-circle {
    width: 350px;
    margin-right: -170px;
    padding: 35px;
    bottom: -175px;
    right: 50%;
    position: absolute;
  }
  .pb-whychoose-panel .feature-circle .panel-feature {
    display: none;
  }
  .pb-whychoose-panel .feature-carousel, .pb-whychoose-panel .feature-carousel-nav {
    margin-top: 0;
    display: block;
  }
}
@media (max-width: 460px) {
  .pb-whychoose-panel .feature-carousel-nav {
    display: none;
  }
}
@media (max-width: 400px) {
  .pb-whychoose-panel {
    padding-bottom: 115px;
  }
  .pb-whychoose-panel .feature-circle {
    width: 300px;
    margin-right: -150px;
    bottom: -150px;
  }
}

.home .pb-latest-postscarousel .midcol {
  margin-top: 4.5em;
}
.home .solutions {
  margin-bottom: 150px;
}
@media (max-width: 768px) {
  .home .pb-latest-postscarousel .midcol {
    margin-top: 7.5em;
  }
}