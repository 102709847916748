@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-whychoose-panel {
    h3 + p {
        margin-top: 2.25rem;
    }
    margin-top: 4.5em;
    padding: 100px 0 350px;
    position: relative;
    .midcol {
        .leftcol {
            flex-basis: 50%;
        }
    }
    .copy-wrapper {
        display: flex;
        .leftcol {
            flex-basis: 50%;
            min-width: 0;
            p {
                max-width: 520px;
            }
        }
    }
    .feature-circle {
        background-image: url('../../assets/images/faded_ring.svg');
        background-size: 108%;
        background-repeat: no-repeat;
        background-position: center;
        width: 50%;
        max-width: 805px;
        padding: 80px;
        right: -7%;
        top: -24%;
        position: absolute;
        img {
            display: block;
        }
        .panel-feature {
            background-color: $atlasred;
            color: white;
            width: 8%;
            padding-top: 8%;
            border-radius: 50%;
            position: absolute;
            overflow: hidden;
            transition: all 0.25s linear;
            .background, .content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                left: 0;
                top: 0;
                position: absolute;
            }
            .background {
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 50%;
                opacity: 1;
            }
            .content {
                opacity: 0;
                .feature-description {
                    min-width: 180px;
                    max-width: 100%;
                    padding: 10px 10px 0;
                    font-size: clamp(13px, 1vw, 16px);
                    text-align: center;
                    line-height: 1.2em;
                    display: block;
                }
            }
            &.active {
                background-image: none !important;
                width: 22.5%;
                padding-top: 22.5%;
                .background {
                    opacity: 0;
                }
                .content {
                    opacity: 1;
                    img {
                        width: auto;
                        height: 40px;
                    }
                }
            }
        }
        #pfeature-1 {
            left: 2%;
            top: 31%;
        }
        #pfeature-2 {
            left: -0.15%;
            top: 44%;
            &.active {
                left: -6.5%;
                top: 43.25%;
            }
        }
        #pfeature-3 {
            left: 5.75%;
            top: 69.75%;
            &.active {
                left: -4.25%;
                top: 56%;
            }
        }
        #pfeature-4 {
            left: 14%;
            top: 80%;
        }
        #pfeature-5 {
            left: 25.5%;
            top: 87.25%;
        }
        &.circle-target-1 {
            #pfeature-1 {
                &.active {
                    left: -7.75%;
                    top: 30.15%;
                }
            }
            #pfeature-2 {
                left: 1.2%;
                top: 57.5%;
            }
        }
        &.circle-target-4 {
            #pfeature-4 {
                &.active {
                    left: 2%;
                    top: 69%;
                }
            }
            #pfeature-3 {
                left: 1.2%;
                top: 57.5%;
            }
        }
        &.circle-target-5 {
            #pfeature-5 {
                &.active {
                    left: 12.5%;
                    top: 78.5%;
                }
            }
            #pfeature-3 {
                left: 1.2%;
                top: 57.5%;
            }
            #pfeature-4 {
                left: 5.75%;
                top: 69.75%;
            }
        }
    }
    .feature-carousel {
        max-width: 410px;
        margin: 45px auto 0;
        position: relative;
        z-index: 10;
        display: none;
        img {
            display: block;
        }
        .panel-feature {
            background-color: $atlasred;
            color: white;
            width: 60px;
            margin: 6px;
            padding-top: 60px;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            transition: all 0.25s linear;
            .background, .content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                left: 0;
                top: 0;
                position: absolute;
            }
            .background {
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 50%;
                opacity: 1;
            }
            .content {
                opacity: 0;
                .feature-description {
                    min-width: 120px;
                    max-width: 100%;
                    padding: 10px 10px 0;
                    font-size: 11px;
                    text-align: center;
                    line-height: 1.2em;
                    display: block;
                }
            }
        }
        .slick-track {
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 550ms ease-in-out;
        }
        .slick-slide {
            height: 60px;
            &.slick-current {
                height: 120px;
                .panel-feature {
                    background-image: none !important;
                    width: 120px;
                    padding-top: 120px;
                    .background {
                        opacity: 0;
                    }
                    .content {
                        opacity: 1;
                        img {
                            width: auto;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
    .feature-carousel-nav {
        width: 100%;
        height: 14px;
        padding: 0 12px;
        transform: translateY(-75px);
        max-width: 475px;
        margin: 0 auto;
        display: none;
        .prev-btn-feature-carousel, .next-btn-feature-carousel {
            background-image: url('../../assets/images/icon_carousel_arrow_red.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 10px;
            height: 14px;
            display: block;
            float: left;
        }
        .prev-btn-feature-carousel {
            transform: rotate(180deg);
        }
        .next-btn-feature-carousel {
            float: right;
        }
    }
    @media(max-width:1200px) {
        .feature-circle {
            .panel-feature {
                .content {
                    .feature-description {
                        min-width: 100%;
                        font-size: 1vw;
                    }
                }
            }
        }
    }
    @media(max-width:1000px) {
        margin-top: 0;
        padding-top: var(--pbpadding);
        padding-bottom: 10em;
        .copy-wrapper {
            display: block;
            .leftcol {
                width: 100%;
                margin-right: 0;
                text-align: center;
                p {
                    margin-left: auto;
                    margin-right: auto;
                }
                .key-heading {
                    margin: 0 auto;
                }
            }
        }
        .feature-circle {
            width: 85%;
            margin: 2em auto 0;
            top: inherit;
            right: -1.5em;
            position: relative;
            .panel-feature {
                .content {
                    .feature-description {
                        font-size: 1.7vw;
                    }
                }
            }
        }
    }
    @media(max-width:767px) {
        margin-bottom: 10.25em;
        padding-bottom: 125px;
        .feature-circle {
            width: 350px;
            margin-right: -170px;
            padding: 35px;
            bottom: -175px;
            right: 50%;
            position: absolute;
            .panel-feature {
                display: none;
            }
        }
        .feature-carousel, .feature-carousel-nav {
            margin-top: 0;
            display: block;
        }
    }
    @media(max-width:460px) {
        .feature-carousel-nav {
            display: none;
        }
    }
    @media(max-width:400px) {
        padding-bottom: 115px;
        .feature-circle {
            width: 300px;
            margin-right: -150px;
            bottom: -150px;
        }
    }
}

.home {
    .pb-latest-postscarousel {
        .midcol {
            margin-top: 4.5em;
        }
    }
    .solutions {
        margin-bottom: 150px;
    }
    @include bpr-media-breakpoint(down, md) {
        .pb-latest-postscarousel {
            .midcol {
                margin-top: 7.5em;
            }
        }
    }
}
